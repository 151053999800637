import { keepNumber } from '@assets/js/regExUtil';
import { getDateNoTime } from '@assets/js/dateUtils';

export const fppctableProperties = [
  {
    label: '采购部门',
    prop: 'podr_dept_name',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    filterable: true,
    widthAuto: true
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  {
    label: '订单总数',
    prop: 'order_sum',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '成品货号总数',
    prop: 'podr_part_count',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '成品数量',
    prop: 'podr_sum',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '成品合同总额',
    prop: 'podr_price_sum',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '准时率(%)',
    prop: 'on_time_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.on_time_rate = keepNumber(val))
    }
  },
  {
    label: '合格率(%)',
    prop: 'pass_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.pass_rate = keepNumber(val))
    }
  },
  {
    label: '工厂配合度(%)',
    prop: 'factory_cooperation_degree',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.factory_cooperation_degree = keepNumber(val))
    }
  },
  {
    label: '生产配合度打分',
    prop: 'production_cooperation_degree',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.production_cooperation_degree = keepNumber(val))
    }
  }
];

export const prodTableProperties = [
  {
    label: '采购部门',
    prop: 'prodDeptName',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.podr_dept_name
  },
  {
    label: '采购人员',
    prop: 'prodStffNamelist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.prod_stff_name
  },
  {
    label: '供应商简称',
    prop: 'suppAbbrlist',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.supp_abbr
  },
  {
    label: '客户简称',
    prop: 'custAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 160,
    collapseTags: true,
    filterable: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cust_abbr
  },
  {
    label: '最终客户',
    prop: 'custBabbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cust_babbr
  },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, labelWidth: 160 },
  {
    label: '中文品名',
    prop: 'cn_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160
  },
  { label: '数量', prop: 'control_num', itemType: 'input', input: false, sortable: false, labelWidth: 160 },
  { label: '产品单价', prop: 'product_price', itemType: 'input', input: false, sortable: false, labelWidth: 160, align: 'right' },
  { label: '总金额', prop: 'sum_money', itemType: 'input', input: false, sortable: false, labelWidth: 160, align: 'right' },
  {
    label: '计划交期',
    prop: 'plan_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: false,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '实际交期',
    prop: 'actual_delivery_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: false,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '晚交货原因',
    prop: 'lateness_deli_cause',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140
  },
  {
    label: '准时率',
    prop: 'punctuality',
    itemType: 'select',
    options: [
      { value: 0, label: '准时' },
      { value: 1, label: '不准时' }
    ],
    input: false,
    sortable: false,
    labelWidth: 160,
    formatter: val => (val === 0 ? '准时' : '不准时')
  },
  {
    label: '工厂准时率',
    prop: 'supp_punctuality',
    itemType: 'select',
    options: [
      { value: 0, label: '准时' },
      { value: 1, label: '不准时' }
    ],
    input: false,
    sortable: false,
    labelWidth: 160,
    formatter: val => (val === 0 ? '准时' : '不准时')
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160
  }
];
