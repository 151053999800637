<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <div class="flexV">
          <el-date-picker
            v-if="fppcType === '汇总'"
            size="small"
            class="vg_ml_8 vg_mr_8 topDateRangeSearch"
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="发单开始日期"
            end-placeholder="发单结束日期"
          />
          <el-button type="primary" size="small" class="vd_export" @click="search()" v-if="fppcType === '汇总'">查询</el-button>
          <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新 </el-button>
          <el-button type="success" size="small" class="vd_export" @click="exportExcel()" v-if="fppcType === '汇总'"> 导出Excel</el-button>
          <div style="margin-left: auto" v-if="fppcType === '明细'">
            <div style="display: flex">
              <div style="width: 250px">全部页总数量:{{ total_quantit }}</div>
            </div>
          </div>
        </div>
        <div class="flexV">
          <div style="margin: 5px">
            <el-radio v-model="fppcType" @input="changeType" label="汇总" :disabled="typeDisabled">汇总</el-radio>
            <el-radio v-model="fppcType" @input="changeType" label="明细" :disabled="typeDisabled">明细</el-radio>
          </div>
          <div style="margin-left: auto" v-if="fppcType === '明细'">
            <div style="display: flex">
              <div style="width: 250px">全部页总金额:{{ total_amount }}</div>
            </div>
          </div>
        </div>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        :tableData="tableData"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        :editActived="editActived"
        :editClosed="editClosed"
        :row-click="row => (currentRow = row)"
        @getTableData="getDataList"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { keepNumber } from '@assets/js/regExUtil';
import { getNoCatch } from '@api/request';
import { fppctableProperties, prodTableProperties } from '@/views/StatisticsManagement/FPPCStatistics/fppcs';
import { podrAPI } from '@api/modules/podr';
import { downloadFile, getDept } from '@api/public';
import { prodControlAPI } from '@api/modules/prodControl';

export default {
  name: 'FPPCStatistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      tableProperties: [],
      fppctableProperties: cloneDeep(fppctableProperties),
      prodTableProperties: cloneDeep(prodTableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentRow: {},
      editActivedRow: {},
      fppcType: '汇总',
      total_amount: 0,
      total_quantit: 0,
      typeDisabled: false
    };
  },
  mounted() {
    this.initData();
  },
  beforeDestroy() {
    this.saveRow();
  },
  methods: {
    keepNumber,
    async initData() {
      this.tableProperties = this.fppctableProperties;
      this.tableProperties.find(x => x.prop === 'podr_dept_name').options = await getDept();
      await this.getDataList();
    },
    async changeType() {
      this.$refs.partMultiTable.resetFields();
      this.tableProperties = this.fppcType === '汇总' ? this.fppctableProperties : this.prodTableProperties;
      if (this.fppcType === '汇总') {
        this.currentRow = {};
        this.tableProperties.find(x => x.prop === 'podr_dept_name').options = await getDept();
      }
      this.$refs.partMultiTable.reloadTable();
      await this.getDataList();
    },
    editClosed({ row }) {
      if (JSON.stringify(this.editActivedRow) !== JSON.stringify(row)) this.saveRow(row);
    },
    editActived({ row }) {
      this.editActivedRow = cloneDeep(row);
    },
    async search() {
      await (this.$refs.partMultiTable.currentPage = 1);
      await (this.$refs.partMultiTable.searchForm.page_no = 1);
      await this.getDataList();
    },
    //获取数据
    async getDataList() {
      this.loadFlag = true;
      this.typeDisabled = true;
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      if (this.fppcType === '汇总') {
        let [startTime, endTime] = this.dateRange || [];
        searchForm.startTime = startTime ? startTime / 1000 : null;
        searchForm.endTime = endTime ? endTime / 1000 : null;
        await getNoCatch(podrAPI.get_podr_statistics, searchForm).then(({ data }) => {
          this.tableData = data.list;
          this.totalPage = data.total;
          setTimeout(() => {
            this.loadFlag = false;
            this.typeDisabled = false;
          }, 1000);
        });
      } else {
        searchForm.custBabbrList = searchForm.custBabbrList?.toString();
        searchForm.prodStffNamelist = searchForm.prodStffNamelist?.toString();
        searchForm.suppAbbrlist = searchForm.suppAbbrlist?.toString();
        searchForm.custAbbrList = searchForm.custAbbrList?.toString();
        searchForm.prodDeptName = searchForm.prodDeptName?.toString();
        searchForm.plan_time = searchForm.plan_time ? searchForm.plan_time / 1000 : null;
        searchForm.prodcontrol_type = 0;
        await prodControlAPI.getProdControl(searchForm).then(({ data }) => {
          this.tableData = data.list;
          this.totalPage = data.total;
          this.total_amount = data.total_amount;
          this.total_quantit = data.total_quantit;
          this.prodTableProperties.find(({ prop }) => prop === 'custBabbrList').options = data.custBabbrList;
          this.prodTableProperties.find(({ prop }) => prop === 'prodStffNamelist').options = data.prodStffNamelist;
          this.prodTableProperties.find(({ prop }) => prop === 'suppAbbrlist').options = data.suppAbbrlist;
          this.prodTableProperties.find(({ prop }) => prop === 'custAbbrList').options = data.custAbbrList;
          this.prodTableProperties.find(({ prop }) => prop === 'prodDeptName').options = data.prodDeptName;
          setTimeout(() => {
            this.loadFlag = false;
            this.typeDisabled = false;
          }, 1000);
        });
      }
    },
    //跨分页导出excel
    exportExcel() {
      let requestBody = { type: 1, ...this.$refs.partMultiTable.searchForm };
      podrAPI.exportExcel(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.xlsx' });
      });
    },
    //刷新
    buttonRefresh() {
      if (this.fppcType === '汇总') {
        this.saveRow(this.currentRow);
      }
      this.$refs.partMultiTable.resetFields();
      this.dateRange = [];
      this.getDataList();
    },
    async saveRow(row) {
      let cr = row ? row : this.currentRow;
      if (!cr || cr === {}) return;
      let isNew = cr.podr_statistics_id;
      let { code, data } = isNew ? await podrAPI.edit_podr_statistics(cr) : await podrAPI.add_podr_statistics(cr);
      if (code === 0) cr.podr_statistics_id = data.form_id;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
